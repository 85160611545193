import Swal from "sweetalert2";

export default class Quote {
  
  constructor() {
  }

  fillQuoteParamsWithAnswers() {
  } 

  resetQuoteInfo(quote_provider){

    var loading = document.getElementById("divLoading"+quote_provider)
    var quoteInfo = document.getElementById("totalContent"+quote_provider);
    var coverageInfo = document.getElementById("totalCoverages"+quote_provider);
    var issuanceQuote = document.getElementById("issuanceQuote"+quote_provider);
    var showCoverage = document.getElementById("btnShowCoverages"+quote_provider);

    loading.style.display = "block";
    quoteInfo.style.display = "none";
    coverageInfo.style.display = "none";
    issuanceQuote.style.display = "none";
    showCoverage.style.display = "none";

  }

  organizeQuoteInfo(quote_provider){

    var loading = document.getElementById("divLoading"+quote_provider.proveedor)
    var quoteInfo = document.getElementById("totalContent"+quote_provider.proveedor);
    var coverageInfo = document.getElementById("totalCoverages"+quote_provider.proveedor);
    var issuanceQuote = document.getElementById("issuanceQuote"+quote_provider.proveedor);
    var showCoverage = document.getElementById("btnShowCoverages"+quote_provider.proveedor);

    quoteInfo.innerHTML = this.setQuoteProviderRow(quote_provider);
      
    coverageInfo.innerHTML = this.setCoveragesRow(quote_provider);

    loading.style.display = "none";
    quoteInfo.style.display = "block";
    coverageInfo.style.display = "block";
    issuanceQuote.style.display = "block";
    showCoverage.style.display = "block";

  }

  setCoveragesRow(quote_provider){
    
    var coverageRows = "<br><h3 class='text-xs font-medium text-gray-900 tracking-wide uppercase'>Coberturas</h3>";

    quote_provider.informacion_cotizacion.coberturas_principales.forEach(coverage => {
      var row = "<div><svg style='float:left; padding-left : 10px' class='flex-shrink-0 h-5 w-5 text-green-500' x-description='Heroicon name: check' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'><path fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'></path></svg><p class='text-sm text-gray-500' style='padding-left : 20px;float:left;'>"+coverage.nombre+"</p><br><p class='text-base text-orange-500'>"+coverage.suma_asegurada+"</p></div>";

      coverageRows += row;
    });
    
    return coverageRows;
  }

  setQuoteProviderRow(provider){

    var row = "";
    
    var total_amount = provider.informacion_cotizacion.importe_total;
    
    var first_payment = provider.informacion_cotizacion.primer_importe;

    var subsequent_payment = provider.informacion_cotizacion.importe_subsecuente;
    
    var total_amount_without_discount = provider.informacion_cotizacion.importe_total_sin_descuento;

    if(provider.frecuencia_de_pago != "Anual"){
      var message_subsequent_payment = "Pago subsecuente";
      if(total_amount_without_discount.toString() != "NaN"){
        row = "<center><p class='text-2xl text-aKingBlue' >Primer Pago</p>"
        + "<p class='text-2xl font-bold text-aKingBlue mb-3 preciototal' id='primerRecibo'>$"+ first_payment +"</p>"
        + "<center><p class='text-xl text-aKingBlue' >" + message_subsequent_payment + "</p>"
        + "<p class='text-xl font-bold text-aKingBlue mb-3 preciototal' id='reciboConsecutivo'>$"+ subsequent_payment +"</p>"
        + "<p class='text-base text-aKingBlue preciototal'>Total a pagar</p>"
        + "<strike class='text-xs font-bold text-red-500 mb-3' id='total_sin_descuento'>$"+ total_amount_without_discount+"</strike>"
        + "<p class='text-3xl font-extrabold text-gray-900' id='total'>$"+ total_amount +"</p></center>";
      }else{
        row = "<center><p class='text-2xl text-aKingBlue' >Primer Pago</p>"
        + "<p class='text-2xl font-bold text-aKingBlue mb-3 preciototal' id='primerRecibo'>$"+ first_payment +"</p>"
        + "<center><p class='text-xl text-aKingBlue' >" + message_subsequent_payment + "</p>"
        + "<p class='text-xl font-bold text-aKingBlue mb-3 preciototal' id='reciboConsecutivo'>$"+ subsequent_payment +"</p>"
        + "<p class='text-base text-aKingBlue preciototal' >Total a pagar</p>"
        + "<br>"
        + "<p class='text-3xl font-extrabold text-gray-900' id='total'>$"+ total_amount +"</p></center>";
    }
    }else{
      if(total_amount_without_discount.toString() != "NaN"){
        row = "<center><p class='font-semibold preciototal'>Total a pagar</p>"
        + "<strike class='text-base font-bold text-red-500 mb-3' id='total_sin_descuento'>$"+ total_amount_without_discount +"</strike>"
        + "<p class='text-3xl font-extrabold text-gray-900' id='total'>$"+ total_amount +"</p></center>";
      }else{
        row = "<center><p class='font-semibold preciototal'>Total a pagar</p>"
        + "<br>"
        + "<p class='text-3xl font-extrabold text-gray-900' id='total'>$"+ total_amount +"</p></center>"
      }
    }
  
    return row;
  }

  getErrorRow(provider){
    var loading = document.getElementById("divLoading"+provider)
    
    var quoteInfo = document.getElementById("totalContent"+provider);
    quoteInfo.innerHTML = "<p class='text-3xl font-semibold'>No disponible</p>";
    loading.style.display = "none";
    quoteInfo.style.display = "block";
  }

  addRowsCoverage(coverages) {
    var coverageRows = "";
    coverages.forEach(coverage => {
      coverageRows += "<tr class='mb-1' style='border-bottom: 1px solid #e2e8f0;'>"+
                        "<td style='font-size: 0.85em;'>"+coverage.nombre+"</td>"+
                        "<td style='font-size: 0.85em;'> "+coverage.suma_asegurada+"</td>"+
                      "</tr>"
    });
    return coverageRows;
  }

  showCoverages(provider) {
    provider.value = provider;

    var thead = "<table style='width:100%'><thead><tr><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;''>Coberturas</th><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;'' style='width: 50%;'>Suma asegurada</th></tr></thead><tbody>";

    var thead2 = "<table style='width:100%'><thead><tr><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;'>Coberturas Especiales</th><th class='bg-orange-500 text-sm font-semibold text-white text-center text-align: -webkit-center; text-align: -moz-center; text-align: -khtml-center; text-align: -ms-center; text-align: center;' style='width: 50%;'' style='width: 50%;'>Suma asegurada</th></tr></thead><tbody>";
    
    var detailedCoverages = thead + this.addRowsCoverage(provider.informacion_cotizacion.coberturas_basicas) + "</tbody></table><br/><br/>" + thead2 +  this.addRowsCoverage(provider.informacion_cotizacion.coberturas_especiales);
    
    Swal.fire({
      title: 'Listado de coberturas',
      html: detailedCoverages,
      confirmButtonText: "OK",
    })
  }

  setQuoteDatesAndId(quotation, providers){
    var formato = {year: 'numeric', month: 'long', day: 'numeric'};
    var fechaCotizacion = new Date(quotation.fecha_cotizacion);
    var fechaDeExpiracion = new Date(quotation.fecha_expiracion);
    var fechaActual = new Date();

    var quotationInfo = document.getElementById("divIdDates")
    
    
    if (fechaActual>fechaDeExpiracion){
      quotationInfo.innerHTML = "<span class='blue mb05 mt05 pa1' style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'> No. de Folio: " + quotation.id +"</span>" +
      "<span class='blue mb05 mt05 pa1' style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'> Fechaa: " + fechaCotizacion.toLocaleDateString("es-ES",formato) + "</span>" +
      "<span class='blue mb05 mt05 pa1' style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'> Expiró el: " + fechaDeExpiracion.toLocaleDateString("es-ES",formato) + "</span>"  
      providers.forEach(proveedor => {
        var issuanceQuote = document.getElementById("issuanceQuote"+proveedor.nombre);
        issuanceQuote.style.display = "none";
      });
    }else{
      quotationInfo.innerHTML = "<span class='blue mb05 mt05 pa1' style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'> No. de Folio: " + quotation.id +"</span>" +
      "<span class='blue mb05 mt05 pa1' style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'> Fechas: " + fechaCotizacion.toLocaleDateString("es-ES",formato) + "</span>" +
      "<span class='blue mb05 mt05 pa1' style='color: #243c44;margin-bottom: 0.5rem;margin-top: 0.5rem;padding: 1rem;font-size: 1.5rem;'> Válido hasta: " + fechaDeExpiracion.toLocaleDateString("es-ES",formato) + "</span>"  
    }
  }
}