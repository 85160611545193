<template>
  <div>
    <div>
      <svg class="w-8 h-8 text-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
      </svg>
      <p class="font-bold text-3xl">¿Qué deseas asegurar?</p>
      <!--p id="one_product" style="display:none;" class="font-bold text-3xl">Actualmente tenemos un servicio increíble para proteger todos los aspectos de tu vida. ¿Qué deseas asegurar?</p>
      <p id="few_products" class="font-bold text-3xl">Actualmente tenemos {{products.length}} servicios increíbles para proteger todos los aspectos de tu vida. ¿Qué deseas asegurar?</p-->
      <div class="space-y-4 mt-6">
        <!-- <form id="select_product">
          <input type="radio" id="Autos" name="selected_product" value="Autos">
          <label for="html">Seguro de Auto</label><br>
          <input type="radio" id="AxaKeralty" name="selected_product" value="AxaKeralty">
          <label for="html">Axa Keralty</label><br>
        </form> -->
        <!--p>Puedes seleccionar varios</p-->
        <!-- <div v-for="product in products" :key="product.id" class="w-full rounded-md border-2 border-gray-500 group hover:border-red-500 cursor-pointer" :class="{ 'border-red-500': aspects.includes(product.id) }" @click="aspects.includes(product.id)? aspects = aspects.filter(function(item) {return item !== product.id}) : aspects.push(product.id)">
          <div class="mt-1 relative shadow-sm">
            <div :class="{ 'text-red-500': aspects.includes(product.id) }" class="py-3 px-4  w-full group-hover:text-red-500  text-gray-600 flex items-center justify-between">
              {{product.nombre}}
              <svg v-show="aspects.includes(product.id)" class="w-5 h-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>
        </div> -->
      </div>

      <!-- <div class="text-xs text-blue-400 text-center mt-4 flex items-center hover:underline cursor-pointer" @click="help = true">
        <svg class="w-4 h-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p>No conozco el producto de Auto.</p>
      </div> -->

      <!-- <p class="text-red-500 text-xs italic mt-5">{{message}}</p> -->
      
      <div class="grid md:grid-cols-3 grid-cols-2 gap-4">
        <span class="inline-flex rounded-md shadow-sm ml-1">
          <button type="button" id="btn_next" @click="car_insurance()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
            <a href="">
              <img src="@/assets/images/products/icon_AUTO.svg" style="width: 100%" fill="none" stroke="currentColor"> 
                Seguro de Auto
            </a>
          </button>
        </span>
        
        <!-- <span class="inline-flex rounded-md shadow-sm ml-1">
          <button type="button" id="btn_car_business" @click="car_business_insurance()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
          <button type="button" id="btn_car_business" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
            <a href="https://api.whatsapp.com/send?phone=+521+6441559264&text=%C2%A1Hola!%20me%20brinda%20información%20del%20seguro%20de%20flotilla%20?%20">
            <a >
              <img src="@/assets/images/products/icon_FLOTILLA.svg" style="width: 100%" fill="none" stroke="currentColor"> 
                Flotilla
            </a>
          </button>
        </span>
      
        <span class="inline-flex rounded-md shadow-sm ml-1">
          <button type="button" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
            <a href="https://api.whatsapp.com/send?phone=+521+6441559264&text=%C2%A1Hola!%20deseo%20cotizar%20un%20seguro%20de%20Gastos%20M%C3%A9dicos%20Mayores%20%C2%BFme%20pueden%20ayudar?%20">
            <a>
              <img src="@/assets/images/products/icon_GMM.svg" style="width: 100%" fill="none" stroke="currentColor"> 
                Gastos Médicos
            </a>
          </button>
        </span>
        <span class="inline-flex rounded-md shadow-sm ml-1">
          <button type="button" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
            <a href="https://api.whatsapp.com/send?phone=+521+6441559264&text=%C2%A1Hola!%20busco%20cotizar%20un%20seguro%20de%20vida%20por%20favor%20">
            <a>
              <img src="@/assets/images/products/icon_VIDA.svg" style="width: 100%" fill="none" stroke="currentColor"> 
                Vida
            </a>
          </button>
        </span>
        <span class="inline-flex rounded-md shadow-sm ml-1">
          <button type="button" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
            <a href="https://api.whatsapp.com/send?phone=+521+6441559264&text=%C2%A1Hola!%20busco%20ayuda%20para%20asegurar%20mi%20casa%C2%BFme%20pueden%20ayudar?%20%C2%A1gracias!%20">
            <a>
              <img src="@/assets/images/products/icon_CASA.svg" style="width: 100%" fill="none" stroke="currentColor"> 
                Casa Habitación
            </a>
          </button>
        </span>

        <span class="inline-flex rounded-md shadow-sm mr-1">
          <button type="button" id="btn_health" @click="health_insurance()" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
          <button type="button" id="btn_health" class="w-full inline-flex items-center justify-center px-6 py-3 border border-orange-500 text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700 transition ease-in-out duration-150">
            <a href="https://api.whatsapp.com/send?phone=+521+6441559264&text=%C2%A1Hola!%20me%20brinda%20información%20de%20AXA%20Keralty%20por%20favor%20?%20">
            <a>
              <img src="@/assets/images/products/icon_KERALTY.svg" style="width: 100%" fill="none" stroke="currentColor"> 
                Axa Keralty
            </a>
          </button>
        </span> -->
        
      </div>
      <!-- <div class="flex justify-between mt-1">
        <span class="span-keralty-product inline-flex rounded-md shadow-sm mr-1 font-bold">
          Axa Keralty
        </span>
        
        <span class="span-car-product inline-flex rounded-md shadow-sm ml-1 font-bold">
          Seguro de Auto
        </span>
        <span class="span-car-product inline-flex rounded-md shadow-sm ml-1 font-bold">
          Seguro de Auto
        </span>

        <span class="span-car-product inline-flex rounded-md shadow-sm ml-1 font-bold">
          Seguro de Auto
        </span>
        <span class="span-car-product inline-flex rounded-md shadow-sm ml-1 font-bold">
          Seguro de Auto
        </span>
        <span class="span-car-product inline-flex rounded-md shadow-sm ml-1 font-bold">
          Seguro de Auto
        </span>
      </div> -->
    </div>
    <help v-show="help" />
  </div>
</template>

<script>

  import {
    inject,
    onMounted
  } from "vue";
  import {
    useRouter
  } from 'vue-router';
  // import axios from "axios";
  // import Category from "@/classes/category.js";
  // import Swal from "sweetalert2";
  import Help from '@/components/quoter/Help.vue';
  export default {
    name: "Products",
    components: {
      Help
    },
    setup() {
      const router = useRouter();
      const help = inject('help');
      // const arrayIdQuestions = ref([]);
      // const message = ref("");
      // const json = ref([]);

      // const categories = inject('categories');
      const step = inject('step');
      const aspects = inject('aspects');
      const products = inject('products');
      // const isLoading = inject('isLoading');
      // const selectedProducts = inject('selectedProducts');
      const arrayAnswers = inject('arrayAnswers');

      onMounted(() => {
        // getProducts();
        arrayAnswers.value = [];
        localStorage.clear();
      });

      // async function getProducts() {
      //   isLoading.value = true;
        
      //   let requestUrl = multicrapi + "/productos/obtener_activos";
      //   await axios.get(requestUrl, config.value).then((response) => {
      //     products.value = response.data.respuesta;
      //   }).catch((error) => {
      //     console.log("Se presento un error al obtener los productos: ", error);
      //   }).finally(() => {
      //     isLoading.value = false;
      //   })
      // }

      // async function getQuestionsBySelectedProducts() {
      //   try {
      //     // if (aspects.value.length > 0) {
      //     isLoading.value = true;
      //     let requestUrl = multicrapi + "/pregunta/obtener_por_productos_y_categorias?procedimiento_almacenado=sp_obtener_preguntas_por_productos_y_categorias&nombre_productos[]=Auto&categorias[]=PERSONALES&categorias[]=COTIZACION";
          
      //     await axios.get(requestUrl, config.value).then((response) => {
      //       json.value = response.data.respuesta;
      //       console.log("response ", response);

      //       localStorage.setItem("JSON", JSON.stringify(response.data.respuesta));
      //     }).catch((error) => {
      //       console.log("Se presento un error al obtener las preguntas: ", error);
      //     }).finally(() => {
      //       isLoading.value = false;
      //     })

      //     validateQuestions();
      //     // } 
      //     // else {
      //     //   message.value = "Seleccione un producto"
      //     //   return;
      //     // }
      //   } catch (error) {
      //     console.log("Componente Producto: Se presento un error: ", error);
      //   }
      // }

      // function validateQuestions() {
      //   console.log("json: ", json.value);
      //   if (json.value.length > 0) {
      //     for (let i = 0; i < json.value.length; i++) { //categories
      //       const categories = json.value[i];
      //       for (let j = 0; j < categories.steps.length; j++) { //steps
      //         const arrayQuestions = categories.steps[j].questions;
      //         for (let k = 0; k < arrayQuestions.length; k++) { //questions   
      //           if (arrayIdQuestions.value.length == 0) {
      //             arrayIdQuestions.value.push(arrayQuestions[k].id);
      //           } else {
      //             const found = arrayIdQuestions.value.find(element => element == arrayQuestions[k].id);
      //             if (found == undefined) {
      //               arrayIdQuestions.value.push(arrayQuestions[k].id);
      //             } else {
      //               json.value[i].steps[j].questions.splice(k, 1);
      //               k--;
      //             }
      //           }
      //         }
      //         if (arrayQuestions.length == 0) {
      //           json.value[i].steps.splice(j, 1);
      //           j--;
      //         }
      //       }
      //     }
      //     step.value++;
      //     router.push({
      //       name: "Questions"
      //     });
      //   } else {
      //     Swal.fire({
      //       title: "Aviso",
      //       text: "No se encontró información con los datos seleccionados.",
      //       icon: "info",
      //     });
      //     return;
      //   }
      //   console.log("json modificado", json.value);
      //   let arrayCategories = Category.buildFromJson(json.value);
      //   arrayCategories.forEach(element => {
      //     categories.value.push(element);
      //   });

      //   console.log("categories.value ", categories.value);
      // }

      function car_insurance() {
        step.value++;
        router.push({
          name: "Questions"
        });
      }

      function car_business_insurance() {
        router.push({
          name: "Car Business services"
        });
      }

      function health_insurance() {
        router.push({
          name: "Health"
        });
      }
      

      return {
        step,
        aspects,
        products,
        // getQuestionsBySelectedProducts,
        // message,
        help,
        car_insurance,
        car_business_insurance,
        health_insurance
      }
    },
  }
</script>
<style>
@media (max-width: 900px) {
  .modal-active {
    margin-top: 75vh;
    margin-bottom: 100px;
  }
  .modal {
    overflow-y: scroll;
  }
}
</style>