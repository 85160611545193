<template>
  <div>
    <div class="vld-parent">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :color="'#bfd243'"
        :height="120"
        :width="400"
        :is-full-page="true"
      >
      </loading>
    </div>
    <div v-if="typeTag == 'text' || typeTag == 'number'">
      <input
        :type="typeTag"
        :class="customClass"
        :id="questionId"
        :name="answer"
        @change="emitUpdateInput"
        @keyup="capitalize"
        @keyup.enter="$emit('input-enter')"
      />
      <div class="p-2">
        <div class="-mt-6  mb-5">
          <div
            class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
          >
            <p :id="'alert' + questionId"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="typeTag == 'date'" :id="questionId">
      <!-- //campo de seleccion de fecha de nacimiento del cliente  -->
       <!-- <input :type="typeTag" :class="customClass" :id="questionId" :name="answer" @change="emitUpdateInput"/>  -->
      <div style="display:flex;" :class="customClass" >
        <input
          type="number"
          placeholder="DD"
          :id="questionId+'day'"
          style="width:100%;text-align: center"
          class="clear_focus"
          @change="selectedDay()"
          name="select_date_day"
          onkeypress='if(this.value.length == 2) return false;'
          oninput="validity.valid||(value='');"
          min="1"
          max="31"
          @keyup.enter="$emit('input-enter')"
        >
        |
        <select
          name="select_date_month"
          :id="questionId+'month'"
          style="width:100%;text-align: center;margin-right:10px"
          class="clear_focus"
          disabled="true"
          @change="selectedMonth()"
          placeholed="MM"
        >
          <option disabled selected value="0">MM</option>
          <option value="1">ENERO</option>
          <option value="2">FEBRERO</option>
          <option value="3">MARZO</option>
          <option value="4">ABRIL</option>
          <option value="5">MAYO</option>
          <option value="6">JUNIO</option>
          <option value="7">JULIO</option>
          <option value="8">AGOSTO</option>
          <option value="9">SEPTIEMBRE</option>
          <option value="10">OCTUBRE</option>
          <option value="11">NOVIEMBRE</option>
          <option value="12">DICIEMBRE</option> 
        </select>
        |        
        <input
          type="number"
          placeholder="AAAA"
          :id="questionId+'year'"
          name="select_date_year"
          style="width:100%;text-align: center"
          class="clear_focus"
          disabled="true"
          @change="selectedYear()"
          onkeypress='if(value.length == 4) return false;'
          oninput="validity.valid||(value='');"
          min="1"
          max="2022"
          >
      </div>
      <div class="p-2">
        <div class="-mt-6  mb-5">
          <div
            class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
          >
            <p :id="'alert' + questionId"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="arrayOptions.length == 0">
      <div v-if="typeTag == 'radio'">
        <div>
          <input
            :type="typeTag"
            :class="customClass"
            value="Si"
            :name="questionId"
            @change="emitUpdateInput"
            id="Si"
            :child="childs"
            @keyup.enter="$emit('input-enter')"
          />{{ "Si" }}
          <input
            :type="typeTag"
            :class="customClass"
            value="No"
            :name="questionId"
            @change="emitUpdateInput"
            id="No"
            :child="childs"
            @keyup.enter="$emit('input-enter')"
          />{{ "No" }}
          <div class="p-2 mt-2">
            <div class="-mt-6  mb-5">
              <div
                class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
              >
                <p :id="'alert' + questionId"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeTag == 'date range'">
        <div class="flex flex-group">
          De:
          <input
            type="date"
            :class="customClass"
            :name="questionId"
            @change="emitUpdateInput"
            title="startDate"
            @keyup.enter="$emit('input-enter')"
          />
          A:
          <input
            type="date"
            :class="customClass"
            :name="questionId"
            @change="emitUpdateInput"
            title="endDate"
            @keyup.enter="$emit('input-enter')"
          />
        </div>
        <div class="p-2">
          <div class="-mt-6  mb-5">
            <div
              class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
            >
              <p :id="'alert' + questionId"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="arrayOptions.length > 0">
      <div v-if="typeTag == 'radio'">
        <div v-for="param in arrayParams" :key="param.value">
          <input
            :type="typeTag"
            :class="customClass"
            :value="param.value"
            :name="questionId"
            @change="emitUpdateInput"
            :id="param.id"
            @keyup.enter="$emit('input-enter')"
          />{{ param.value }}
        </div>
        <div class="p-2 mt-2">
          <div class="-mt-6  mb-5">
            <div
              class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
            >
              <p :id="'alert' + questionId"></p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeTag == 'checkbox'">
        <div v-for="param in arrayParams" :key="param.id">
          <input
            :type="typeTag"
            :class="customClass"
            :value="param.value"
            :id="param.id"
            :name="questionId"
            @keyup.enter="$emit('input-enter')"
          />{{ param.value }}
        </div>
        <div class="p-2">
          <div class="-mt-6  mb-5">
            <div
              class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
            >
              <p :id="'alert' + questionId"></p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeTag == 'select' && arrayAddress.length == 0">
        <select
          :class="customClass"
          @blur="emitUpdateInput"
          :id="questionId"
          v-model="answer"
        >
          <option disabled value="0" selected>Seleccionar</option>
          <option
            v-for="param in arrayParams"
            :key="param.id"
            :value="param.value"
            :name="param.id"
            >{{ param.value }}</option
          >
        </select>

        <!-- <select :class="customClass" @blur="emitUpdateInput" :id="questionId" v-model="answer">
        <option disabled value="0" selected>Seleccionar</option>
        <option v-for="param in arrayParams" :key="param.id" :value="param.value" :name="param.id">{{param.value}}</option>
      </select> -->
        <div class="p-2">
          <div class="-mt-6  mb-5">
            <div
              class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
            >
              <p :id="'alert' + questionId"></p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeTag == 'select' && arrayAddress.length != 0">
        <select :class="customClass" @change="emitUpdateInput" :id="questionId">
          <option disabled value="0" selected>Seleccionar</option>
          <option
            v-for="param in arrayAddress"
            :key="param.id"
            :value="param.value"
            :name="param.id"
            :state="param.state"
            :id_state="param.id_state"
            :id_town="param.id_town"
            :town="param.town"
            :id_city="param.id_city"
            :city="param.city"
            >{{ param.value }}</option
          >
        </select>
        <div class="p-2">
          <div class="-mt-6  mb-5">
            <div
              class="text-sm  font-normal  max-w-full flex-initial text-red-700 "
            >
              <p :id="'alert' + questionId"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <custom-input v-model="typeTag"></custom-input> -->

  </div>
</template>
<script>
import Validation from "@/classes/Validation.js";
import DateAndFormatter from "@/classes/DateAndFormatter.js";
// onUnmounted
import { inject, ref, onMounted } from "vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Options",
  data() {
    return {
      dateBirthDay:"dd-mm-yyyy"
    };
  },
  components: {
    Loading,
  },
  props: {
    arrayOptions: {
      type: Array,
    },
    questionType: {
      type: String,
    },
    questionId: {
      type: Number,
    },
    questionDescription: {
      type: String,
    },
    productName: {
      type: String,
    },
    categoryName: {
      type: String,
    },
    subcategoryName: {
      type: String,
    },
    validations: {
      type: Array,
    },
    childQuestions: {
      type: Array,
    },
  },
  setup(props) {

    const questionId = ref("");
    const answer = ref("");
    const answe = ref("");
    const answers = ref([]);
    const typeTag = ref("");
    const customClass = ref("input");
    const arrayParams = ref([]);
    const isLoading = ref(false);
    //const mask = ref("");
    const validated = ref(true);
    const childs = ref([]);

    const selectedProviderQuote = inject("selectedProviderQuote");
    const questionIdGlobal = inject("questionId");
    const arrayIdQuestions = inject("arrayIdQuestions");
    arrayIdQuestions.value = [];
    const contCategory = inject("contCategory");
    const step = inject("step");
    const contSteps = inject("contSteps");
    const arrayAnswers = inject("arrayAnswers");
    const arrayAddress = ref([]);
    // const issuanceParams = inject('issuanceParams');
    const currentSubcategoryName = inject("currentSubcategoryName");
    const currentProductName = inject("currentProductName");
    const titularGender = inject("titularGender");
    //const validationError = inject('validationError');
    const message = inject("message");
    const buttonDisabled = inject("buttonDisabled");
    const clientName = inject("clientName");
    let config = ref({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MX0.qQGhJSHhkDC_WlnE5Hqi8BsUSSSc42YshrYcyAXRX8c`
      }
    });
    const emitUpdateInput = async (event) => {
      //apply validations

      buttonDisabled.value = true;

      message.value = "";

      let element = document.getElementById(event.target.id);

      let index = null;
      let description = event.target.value;
      let answer_id = event.target.name;
      let question_id = event.target.id;

      if (typeTag.value == "select") {
        index = event.target.options.selectedIndex;
      }

      if (typeTag.value == "radio") {
        element = document.getElementsByName(event.target.name);
      }
      /*if(typeTag.value == "text"){
        description = description.charAt(0).toUpperCase() + description.slice(1);
      }*/

      const validation = new Validation(
        event.target.value,
        props.validations,
        index,
        element,
        arrayAnswers
      );
      let response = await validation.applyValidation();
      if (document.getElementById("alert" + props.questionId)) {
        document.getElementById(
          "alert" + props.questionId
        ).innerHTML = response;
        if (
          document.getElementById("alert" + props.questionId).innerHTML == ""
        ) {
          validated.value = true;
        } else {
          validated.value = false;
        }
      }

      if (props.questionType == "BOOLEAN") {
        let labelName = "";
        childs.value.forEach((child) => {
          labelName = "";
          let input = document.getElementById(child);
          if (input == null) {
            let isEmptyRangeDates = false,
              validateRangeDates = [];
            let elements = document.getElementsByName(child);
            elements.forEach((element) => {
              if (element.className == "date-range") {
                if (event.target.value == "No") {
                  element.setAttribute("disabled", "");
                  element.disabled = true;
                  element.value = "";
                  labelName = "alert" + element.name;
                  console.log("alabe", labelName);
                  document.getElementById(labelName).innerText = "";
                } else {
                  element.removeAttribute("disabled");
                  if (element.value) {
                    validateRangeDates.push(element.value);
                  } else {
                    isEmptyRangeDates = true;
                  }
                }
              }
            });

            if (event.target.value == "Si") {
              if (isEmptyRangeDates) {
                if (document.getElementById("alert" + props.questionId)) {
                  document.getElementById("alert" + props.questionId).value ==
                    "Seleccione las dos fechas";
                }
              } else {
                if (document.getElementById("alert" + props.questionId)) {
                  console.log(
                    document.getElementById("alert" + props.questionId)
                  );
                  if (validateRangeDates[0] > validateRangeDates[1]) {
                    labelName = "alert" + props.questionId;
                    document.getElementById(labelName).innerText =
                      "La fecha inicial no puede ser mayor a la fecha final";
                  }
                }
              }
            }
          } else {
            if (event.target.value == "No") {
              input.setAttribute("disabled", "");
              input.disabled = true;
            } else {
              input.removeAttribute("disabled");
            }
          }
        });
      }

      if (typeTag.value == "date range") {
        let dates = JSON.parse(localStorage.getItem(event.target.name));

        if (!dates) {
          dates = {
            startDate: "",
            endDate: "",
          };
        }

        if (event.target.title == "startDate") {
          dates.startDate = event.target.value;
        } else {
          dates.endDate = event.target.value;
        }
        localStorage.setItem(event.target.name, JSON.stringify(dates));

        dates = JSON.parse(localStorage.getItem(event.target.name));

        console.log("starDate ", dates.startDate);
        console.log("endDate ", dates.endDate);
        if (dates.startDate != "" && dates.endDate != "") {
          let date = new Date();
          if (dates.startDate > dates.endDate) {
            let labelName = "alert" + props.questionId;
            document.getElementById(labelName).innerText =
              "La fecha inicial no puede ser mayor a la fecha final";
          }
          if (
            dates.startDate > date.toISOString() ||
            dates.endDate > date.toISOString()
          ) {
            let labelName = "alert" + props.questionId;
            document.getElementById(labelName).innerText = "La fecha ".concat(
              event.target.title == "startDate" ? "inicial" : "final",
              "",
              " seleccionada no puede ser mayor a la fecha actual"
            );
          }
        }
      }

      if (typeTag.value == "date range") {
        let dates = JSON.parse(localStorage.getItem(event.target.name));

        if (!dates) {
          dates = {
            startDate: "",
            endDate: "",
          };
        }

        if (event.target.title == "startDate") {
          dates.startDate = event.target.value;
        } else {
          dates.endDate = event.target.value;
        }
        localStorage.setItem(event.target.name, JSON.stringify(dates));

        dates = JSON.parse(localStorage.getItem(event.target.name));
        console.log("starDate ", dates.startDate);
        console.log("endDate ", dates.endDate);
        if (dates.startDate != "" && dates.endDate != "") {
          let date = new Date();
          if (dates.startDate > dates.endDate) {
            let labelName = "alert" + props.questionId;
            document.getElementById(labelName).innerText =
              "La fecha inicial no puede ser mayor a la fecha final";
          }
          if (
            dates.startDate > date.toISOString() ||
            dates.endDate > date.toISOString()
          ) {
            let labelName = "alert" + props.questionId;
            document.getElementById(labelName).innerText = "La fecha ".concat(
              event.target.title == "startDate" ? "inicial" : "final",
              "",
              " seleccionada no puede ser mayor a la fecha actual"
            );
          }
        }
      }

      //save answers
      if (event.target.value != "") {
        let state = "";
        let id_state = "";
        let id_city = "";
        let city = "";
        let id_town = "";
        let town = "";

        if (typeTag.value == "select") {
          description = event.target.options[index].innerText;

          answer_id =
            event.target.options[event.target.options.selectedIndex].attributes
              .name.nodeValue;
        }
        if (typeTag.value == "radio") {
          answer_id = event.target.id;
          question_id = event.target.name;
        }

        if (typeTag.value == "select" && arrayAddress.value.length > 0) {
          description =
            event.target.options[event.target.options.selectedIndex].innerText;
          state = event.target.options[event.target.options.selectedIndex]
            .attributes.state
            ? event.target.options[event.target.options.selectedIndex]
                .attributes.state.nodeValue
            : "";
          id_state = event.target.options[event.target.options.selectedIndex]
            .attributes.id_state
            ? event.target.options[event.target.options.selectedIndex]
                .attributes.id_state.nodeValue
            : "";
          id_town = event.target.options[event.target.options.selectedIndex]
            .attributes.id_town
            ? event.target.options[event.target.options.selectedIndex]
                .attributes.id_town.nodeValue
            : "";
          town = event.target.options[event.target.options.selectedIndex]
            .attributes.town
            ? event.target.options[event.target.options.selectedIndex]
                .attributes.town.nodeValue
            : "";
          city = event.target.options[event.target.options.selectedIndex]
            .attributes.city
            ? event.target.options[event.target.options.selectedIndex]
                .attributes.city.nodeValue
            : "";
          id_city = event.target.options[event.target.options.selectedIndex]
            .attributes.id_city
            ? event.target.options[event.target.options.selectedIndex]
                .attributes.id_city.nodeValue
            : "";
        }

        if (arrayAnswers.value.length > 0) {
          let i = 0;
          for (let answer of arrayAnswers.value) {
            //arrayAnswers.value.forEach((element, index) => {
            if (answer.question_id == question_id) {
              arrayAnswers.value.splice(i, 1);
            }
            i++;
          } //);
        }
        answer.value = description;

        if (
          arrayAddress.value.length > 0 &&
          props.questionDescription.includes("Colonia")
        ) {
          arrayAnswers.value.push({
            product: props.productName,
            category: props.categoryName,
            subcategory: props.subcategoryName,
            question_id: event.target.id,
            question: props.questionDescription,
            answer_id: answer_id,
            answer: description,
            state: state,
            id_state: id_state,
            id_town: id_town,
            id_city: id_city,
            city: city,
            town: town,
            validated: validated.value,
            questionType: typeTag.value,
          });
        } else {
          arrayAnswers.value.push({
            product: props.productName,
            category: props.categoryName,
            subcategory: props.subcategoryName,
            question_id: question_id,
            question: props.questionDescription,
            answer_id: answer_id,
            answer: description,
            validated: validated.value,
            questionType: typeTag.value,
          });
        }
        if (props.questionDescription.includes("tú eres")) {
          if (event.target.value == "Hombre") {
            titularGender.value = "Masculino";
          } else if (event.target.value == "Mujer") {
            titularGender.value = "Femenino";
          } else {
            titularGender.value = event.target.value;
          }
        }
        if (props.questionDescription.includes("Nombre(s)")) {
          clientName.value = event.target.value;
        }
        localStorage.setItem(
          "arrayAnswers",
          JSON.stringify(arrayAnswers.value)
        );

        buttonDisabled.value = false;
      } else {
        var arrayAnswersNew = JSON.parse(localStorage.getItem("arrayAnswers"));
        arrayAnswers.value = [];
        if (arrayAnswersNew) {
          arrayAnswersNew.forEach((answerInformation) => {
            if (answerInformation.question_id != question_id) {
              arrayAnswers.value.push(answerInformation);
            }
          });

          localStorage.setItem(
            "arrayAnswers",
            JSON.stringify(arrayAnswers.value)
          );
        }
      }
    };


    onMounted(async () => {
      buttonDisabled.value = false;
      currentProductName.value = props.productName;
      currentSubcategoryName.value = props.subcategoryName;

      arrayIdQuestions.value.push({
        id: props.questionId,
        validations: props.validations.length > 0 ? props.validations : [],
        question: props.questionDescription,
      });
      localStorage.setItem(
        "arrayIdQuestions",
        JSON.stringify(arrayIdQuestions.value)
      );


      questionId.value = props.questionId;
      questionIdGlobal.value = questionId.value;
      validateTypeQuestion();
      await validateOption();
      await addAnswer();
      document.getElementById(arrayIdQuestions.value[0].id).focus();
      // addName();
      //addDateBirthday()
      //addValidations()
    });

    //async function addDateBirthday(){
    //  if(props.questionId=="16"){
    //    if(document.getElementById(props.questionId).value!=undefined){
    //      let date=document.getElementById(props.questionId).value;
    //      let day=new DateAndFormatter().getDayOrMonthOrYear(8,9,date)
    //      let mounth=new DateAndFormatter().getDayOrMonthOrYear(5,6,date)
    //      let year=new DateAndFormatter().getDayOrMonthOrYear(0,3,date)
    //      document.getElementById((props.questionId+"day")).value=day;
    //      document.getElementById((props.questionId+"month")).value=parseInt(mounth);
    //      document.getElementById((props.questionId+"year")).value=parseInt(year);    
    //      document.getElementsByName("select_date_month")[0].disabled = false;
    //      document.getElementsByName("select_date_year")[0].disabled = false;
    //    } 
    //  }
    //}

    function capitalize(event) {
      let description = event.target.value;
      if (props.questionDescription != "Correo electrónico") {
        description = description.charAt(0).toUpperCase() + description.slice(1);
      }
      document.getElementById(event.target.id).value = description;
    }

    function validateTypeQuestion() {
      switch (props.questionType) {
        case "LIBRE":
          typeTag.value = "text";
          break;
        case "OPCION UNICA":
          if (props.arrayOptions.length == 2) {
            typeTag.value = "radio";
            customClass.value = "radio";
          } else {
            typeTag.value = "select";
            customClass.value = "select";
          }
          break;
        case "OPCION MULTIPLE":
          typeTag.value = "checkbox";
          customClass.value = "checkbox;";
          break;
        case "RANGO DE FECHAS":
          typeTag.value = "date range";
          customClass.value = "date-range";
          break;
        case "FECHA":
          typeTag.value = "date";
          break;
        case "NUMERICA":
          typeTag.value = "number";
          break;
        case "BOOLEAN":
          typeTag.value = "radio";
          customClass.value = "radio";
          childs.value = props.childQuestions;
          break;
        default:
          typeTag.value = "text";
          break;
      }
    }

    function validateOption() {
      props.arrayOptions.forEach((option, index) => {
        if (
          props.arrayOptions[index].endpoint != null &&
          props.arrayOptions[index].endpoint != ""
        ) {
          let requestUrl = props.arrayOptions[index].endpoint;
          let template = props.arrayOptions[index].json_params_template;

          var data = "";
          isLoading.value = true;
          if (props.arrayOptions[index].option.includes("Colonia")) {

            template = JSON.parse(template);
            arrayAnswers.value.forEach(answer => {
              if (answer.question.includes("Código Postal")) {
                template.codigo_postal = answer.answer;
              }
            });

            template.proveedor = selectedProviderQuote.value.proveedor;
            if (template.proveedor == "Hdi" || template.proveedor == "Chubb") {
              template.proveedor = "Gnp";
            }
            
            axios.post(requestUrl, template, config).then((response) => {
              response.data.respuesta.forEach((element) => {
                arrayAddress.value.push({
                  id: element.id,
                  value: element.value,
                  state: element.state,
                  id_state: element.state_id,
                  id_town: element.town_id,
                  town: element.town,
                  id_city: element.city_id,
                  city: element.city,
                });
              });
              localStorage.setItem("arrayAddress", JSON.stringify(arrayAddress.value));
            }).catch((error) => {
              console.log("Se realizó un error al consultar las colonias: ", error.message);
            }).finally(() => {
              isLoading.value = false;
            });
          } else {
            template = buildParamsTemplate(
              props.arrayOptions[index].json_params_template
            );
            data = template == "" ? template : template;

            axios.get(
              requestUrl,{
                params: JSON.parse(data)
              }, config.value
            )
            .then((response) => {
            // axios
            //   .post(
            //     requestUrl,
            //     {json: JSON.parse(data)}, 
            //     config.value
            //   )
            //   .then((response) => {
                response.data.respuesta.resultado.forEach((element) => {
                  arrayParams.value.push(element);
                });
              })
              .catch((error) => {
                console.log("error: ", error.message);
              })
              .finally(() => {
                isLoading.value = false;
              });
          }
          
        } else {
          arrayParams.value.push({
            id: option.id,
            value: option.option,
          });
        }
      });
    }

    function buildParamsTemplate(template) {
      template = JSON.stringify(template);
      if (template != null && template != "") {
        arrayAnswers.value.forEach((answer) => {
          if (answer.category == "COTIZACION"){
            if (template.includes("tipo_auto")) {
              template = template.replace("tipo_auto",answer.answer_id);
            }else{
              if (template.includes("modelo_auto")) {
                template = template.replace("modelo_auto",answer.answer_id);
              }else{
                if (template.includes("marca_id_auto")) {
                  template = template.replace("marca_id_auto",answer.answer_id);
                }else{
                  if (template.includes("submarca_id_vehiculo") && answer.question == "Selecciona el modelo de tu auto") {
                    template = template.replace("submarca_id_vehiculo",answer.answer_id);
                  }
                }
              }            
            }
          }
        });
      }
      return JSON.parse(template);
    }

    function addAnswer() {
      if (arrayAnswers.value.length > 0) {
        for (let item of arrayIdQuestions.value) {
          const found = arrayAnswers.value.find(
            (element) => element.question_id == item.id
          );
          if (found) {
            switch (found.questionType) {
              case "radio":
                if (document.getElementsByName(found.question_id)) {
                  var name = document.getElementsByName(found.question_id);
                  name.forEach((element) => {
                    if (element.id == found.answer_id) {
                      element.checked = true;
                    }
                  });
                }
                break;
              case 'select':
                if (document.getElementById(found.question_id)) {
                  /*document.getElementById(found.question_id).value = found.answer;
                  document.getElementById(found.question_id).focus();
                  console.log("valor del select ",document.getElementById(found.question_id).value);*/
                  let index = arrayAnswers.value.indexOf(found);
                  arrayAnswers.value.splice(index, 1);
                }
                break;
              default:
                document.getElementById(found.question_id).value = found.answer;
                break;
            }
          }
        }
      }
    }

    // function addName() {
    //   for (let item of arrayIdQuestions.value) {
    //     let login = JSON.parse(sessionStorage.getItem("login"));
    //     if (login) {
    //       if (item.question.toUpperCase().includes("NOMBRE")) {
    //         if(login.person.middle_name != null){
    //           document.getElementById(item.id).value = login.person.name.concat(" ", login.person.middle_name);
    //         }else{
    //           document.getElementById(item.id).value = login.person.name;
    //         }
    //       }
    //       if (item.question.toUpperCase().includes("APELLIDO PATERNO")) {
    //         document.getElementById(item.id).value = login.person.paternal_name;
    //       }
    //       if (item.question.toUpperCase().includes("APELLIDO MATERNO")) {
    //         document.getElementById(item.id).value = login.person.maternal_name;
    //       }
    //     }
    //   }
    // }

    return {
      typeTag,
      step,
      contSteps,
      contCategory,
      answer,
      arrayParams,
      validateOption,
      customClass,
      answers,
      answe,
      isLoading,
      emitUpdateInput,
      arrayAddress,
      childs,
      capitalize
    };
  },


  async beforeMount(){
  if(this.$props.questionType=="FECHA"){
    let data=JSON.parse(localStorage.getItem("arrayAnswers"))
    data= data.find(x=>x.questionType=="date")
    if(data==undefined)
    return;
      if(data.questionType=="date"){
        if(data.answer!=undefined || data.answer!=null ||data.answer!=""){
          let day=new DateAndFormatter().getDayOrMonthOrYear(8,9,data.answer)
          let mounth=new DateAndFormatter().getDayOrMonthOrYear(5,6,data.answer)
          let year=new DateAndFormatter().getDayOrMonthOrYear(0,3,data.answer)   
          this.dateBirthDay= day+"-"+mounth+"-"+year;
        } 
      }
  }
  },
  methods: {
    selectedDay() {
      //this.alerDateValidator()
      if (document.getElementById((this.$props.questionId+"day")).value != '' ||
        document.getElementById((this.$props.questionId+"day")).value > 0 && document.getElementById((this.$props.questionId+"day")).value < 32){
      // if (document.getElementById((this.$props.questionId+"day")).value != "" && document.getElementById((this.$props.questionId+"day")).value != "0" && document.getElementById((this.$props.questionId+"day")).value != "00") { 
            this.dateBirthDay = new DateAndFormatter().formattedDay(
          document.getElementById((this.$props.questionId+"day")).value,
          this.dateBirthDay,
        );
        if(document.getElementsByName("select_date_month")[0].disabled == false && document.getElementsByName("select_date_year")[0].disabled == false){
          this.validateDate();
        }else{
          document.getElementsByName("select_date_month")[0].disabled = false;
        }
      }else{
        if(document.getElementsByName("select_date_month")[0].disabled == false && document.getElementsByName("select_date_year")[0].disabled == false){
          var substitute = this.dateBirthDay;
          this.dateBirthDay= '';
          this.validateDate();
          this.dateBirthDay= substitute;
        }
      }
    },

    selectedMonth() {
      if (document.getElementById((this.$props.questionId+"month")).value !="") {
        this.dateBirthDay = new DateAndFormatter().formattedMonth(
          document.getElementById((this.$props.questionId+"month")).value,
          this.dateBirthDay);
        if(document.getElementsByName("select_date_year")[0].disabled == false){
          this.validateDate();
        }else{
          document.getElementsByName("select_date_year")[0].disabled = false;
        }
      }
    },

    selectedYear() {
      if(document.getElementById((this.$props.questionId+"year")).value!=""){
        if( document.getElementById((this.$props.questionId+"year")).value.length==4 && document.getElementById((this.$props.questionId+"year")).value>=1910 && document.getElementById((this.$props.questionId+"year")).value != "0000"){
        this.dateBirthDay = new DateAndFormatter().formattedYear(
        document.getElementById((this.$props.questionId+"year")).value,
        this.dateBirthDay);
        }
        else{
          this.dateBirthDay = new DateAndFormatter().formattedYear(
          "mmmm",
          this.dateBirthDay)
        }
        this.validateDate()
      }else{
        var substitute = this.dateBirthDay;
        this.dateBirthDay= '';
        this.validateDate()
        this.dateBirthDay= substitute;
        
      }
    },
    validateDate() {
    //  let regex = RegExp("[a-zA-Z]");
      // if (!regex.test(this.dateBirthDay)) {
      // if (new DateAndFormatter().isValidDate(this.dateBirthDay) == false) {
          let date=new DateAndFormatter().dateFormat(this.dateBirthDay);
          let event={
              target:{
                value:date,
                name:"Fecha cumpleaños",
                id:this.$props.questionId
              }
            }
             this.emitUpdateInput(event)
        //  }
       //  }
      
    },



  },
};
</script>

<style>
.clear_focus:focus {
  outline: none;
}

.input {
  @apply shadow appearance-none border rounded py-3 px-4 block w-full transition ease-in-out duration-150;
}

.radio {
  @apply m-3 h-4 w-4 text-red-500 transition duration-150 ease-in-out;
}

.select {
  @apply mt-5 mb-5 shadow border rounded py-3 px-4 block w-full transition duration-150 ease-in-out;
}

.date-range {
  @apply mt-5 mb-5 shadow border rounded py-3 px-4 transition duration-150 ease-in-out w-5/12;
}

.disabled {
  @apply opacity-75;
}

</style>

