// import Swal from "sweetalert2";
import axios from "axios";
var multicr_api = process.env.VUE_APP_MULTICR;


export default class Reports {
  
  constructor(initialDate, finalDate, reportType) {
    this.initialDate = initialDate;
    this.finalDate = finalDate;
    this.reportType = reportType;
    this.URL_REPORT_TYPE = URL_REPORT_TYPE;
    this.URL_USR_REPORT_TYPE = URL_USR_REPORT_TYPE;
    this.OPTIONS = OPTIONS;
    this.HEADERS = HEADERS
    this.seller = (sessionStorage.getItem('login')) ? JSON.parse(sessionStorage.getItem('login')).persona.nombres + " " + JSON.parse(sessionStorage.getItem('login')).persona.apellido_paterno : '';
    this.role = (sessionStorage.getItem('login'))? JSON.parse(sessionStorage.getItem('login')).rol: '';
    this.userId = (sessionStorage.getItem('login'))? JSON.parse(sessionStorage.getItem('login')).id: '';
  }

  get(){
    let url = "";
    let params = "";
    if(this.role == 'Administrador'|| this.role == 'Super_Administrador'){
      params = {
        fecha_inicial: this.initialDate,
        fecha_final: this.finalDate,
      };
      url = multicr_api + this.URL_REPORT_TYPE[this.reportType];
    }else{
      params = {
        fecha_inicial: this.initialDate,
        fecha_final: this.finalDate,
        usuario_id: this.userId
      };
      url = multicr_api + this.URL_USR_REPORT_TYPE[this.reportType];
    }
    let options = this.setOptions(params);
    return axios.get(url, options);
  }

  setOptions(params){
    return {
      headers: {
        "Content-Type": "Application/Json",
        Authorization: sessionStorage.getItem("login")
          ? JSON.parse(sessionStorage.getItem("login")).token
          : "",
      },
      params: params,
    };
  }

  setTableHeaders(){
    return this.HEADERS[this.reportType]
  }

  setTableOptions(){
    return this.OPTIONS[this.reportType]
  }

  buildTable(values){
    switch (this.reportType) {
      case "VENTAS":
        return this.buildTableEmisiones(values);
      case "PENDIENTES":
        return this.buildTableEmisiones(values);
      case "COTIZACIONES":
        return this.buildTableCotizaciones(values);
      default:
        break;
    }
  }

  buildTableEmisiones(values){
    let obj = null;
    if(this.role == "Administrador" || this.role=="Super_Administrador"){
      obj=new Object({
        Folio:values.id,
        PolizaPdf: values.impresion_pdf,
        Poliza:values.num_poliza,
        Aseguradora:values.aseguradora,
        Vendedor:values.vendedor,
        Cliente:values.cliente,
        PrimerImporte:values.primer_importe,
        ImporteSubsecuente:values.importe_subsecuente,
        ImporteTotal:values.importe_total,
        FechaEmision:values.fecha_emision,
        InicioVigencia:values.inicio_vigencia,
        FinVigencia:values.fin_vigencia,
        FrecuenciaPago:values.frecuencia_de_pago,
        Descuento:values.descuento,
        NumeroSerie:values.numero_serie,
        ModeloAuto:values.modelo,
        MarcaAuto:values.marca,
        DescripcionAuto:values.descripcion,
        Cobertura:values.cobertura,
        FechaNacimiento:values.fecha_nacimiento,
        Email:values.correo,
        Telefono:values.telefono
      })
    }else{
      obj=new Object({
        Folio:values.id,
        PolizaPdf: values.impresion_pdf,
        Poliza:values.num_poliza,
        Aseguradora:values.aseguradora,
        Vendedor:this.seller,
        Cliente:values.cliente,
        PrimerImporte:values.primer_importe,
        ImporteSubsecuente:values.importe_subsecuente,
        ImporteTotal:values.importe_total,
        FechaEmision:values.fecha_emision,
        InicioVigencia:values.inicio_vigencia,
        FinVigencia:values.fin_vigencia,
        FrecuenciaPago:values.frecuencia_de_pago,
        Descuento:values.descuento,
        NumeroSerie:values.numero_serie,
        ModeloAuto:values.modelo,
        MarcaAuto:values.marca,
        DescripcionAuto:values.descripcion,
        Cobertura:values.cobertura,
        FechaNacimiento:values.fecha_nacimiento,
        Email:values.correo,
        Telefono:values.telefono
      })
    }
    return obj;
  }
  buildTableCotizaciones(values){
    let obj = null;
    if(this.role=="Administrador" || this.role=="Super_Administrador"){
      obj=new Object({
        Cotizacion:values.id,
        Vigencia:values.vigencia,
        Vendedor:values.vendedor,
        Cliente:values.cliente,
        FechaNacimiento:values.fecha_nacimiento,
        Email:values.correo,
        Telefono:values.telefono,
        InicioVigencia:values.inicio_vigencia,
        FinVigencia:values.fin_vigencia,
        FrecuenciaPago:values.frecuencia_de_pago,
        ModeloAuto:values.modelo,
        MarcaAuto:values.marca,
        DescripcionAuto:values.descripcion,
        Cobertura:values.cobertura
      });
    }else{
      obj=new Object({
        Cotizacion:values.id,
        Vigencia:values.vigencia,
        Vendedor:this.seller,
        Cliente:values.cliente,
        FechaNacimiento:values.fecha_nacimiento,
        Email:values.correo,
        Telefono:values.telefono,
        InicioVigencia:values.inicio_vigencia,
        FinVigencia:values.fin_vigencia,
        FrecuenciaPago:values.frecuencia_de_pago,
        ModeloAuto:values.modelo,
        MarcaAuto:values.marca,
        DescripcionAuto:values.descripcion,
        Cobertura:values.cobertura
      })
    }
    return obj;
  }
  
}
const URL_REPORT_TYPE = {
  //VENTAS: "/reportes/obtener_ventas_por_rango_de_fecha",
  //PENDIENTES: "/reportes/obtener_emisiones_por_rango_de_fecha",
  VENTAS: "/reportes/obtener_emisiones_por_rango_de_fecha",
  COTIZACIONES: "/reportes/obtener_cotizaciones_por_rango_de_fecha"
}

const URL_USR_REPORT_TYPE = {
  //VENTAS: "/reportes/obtener_informe_de_ventas_de_usuario_por_rango_de_fecha", 
  //PENDIENTES: "/reportes/obtener_informe_de_emisiones_de_usuario_por_rango_de_fecha",
  VENTAS: "/reportes/obtener_informe_de_emisiones_de_usuario_por_rango_de_fecha",
  COTIZACIONES: "/reportes/obtener_informe_de_cotizaciones_de_usuario_por_rango_de_fecha",
}

const OPTIONS = {
  VENTAS: [
    "id",
    "num_poliza",
    "aseguradora",
    "vendedor",
    "cliente",
    "primer_importe",
    "importe_subsecuente",
    "importe_total",
    "fecha_emision",
    "inicio_vigencia",
    "fin_vigencia",
    "frecuencia_de_pago",
    "descuento",
    "numero_serie",
    "modelo",
    "marca",
    "descripcion",
    "cobertura",
    "fecha_nacimiento",
    "correo",
    "telefono"
  ],
  PENDIENTES: [
    "id",
    "num_poliza",
    "aseguradora",
    "vendedor",
    "cliente",
    "primer_importe",
    "importe_subsecuente",
    "importe_total",
    "fecha_emision",
    "inicio_vigencia",
    "fin_vigencia",
    "frecuencia_de_pago",
    "descuento",
    "numero_serie",
    "modelo",
    "marca",
    "descripcion",
    "cobertura",
    "fecha_nacimiento",
    "correo",
    "telefono"
  ],
  COTIZACIONES: [
    "id",
    "vigencia",
    "vendedor",
    "cliente",
    "fecha_nacimiento",
    "correo",
    "telefono",
    "inicio_vigencia",
    "fin_vigencia",
    "frecuencia_de_pago",
    "modelo",
    "marca",
    "descripcion",
    "cobertura"
  ]
}
const HEADERS = {
  VENTAS: [
    "Folio",
    "Poliza",
    "Aseguradora",
    "Vendedor",
    "Cliente",
    "PrimerImporte",
    "ImporteSubsecuente",
    "ImporteTotal",
    "FechaEmisión",
    "InicioVigencia",
    "FinVigencia",
    "FrecuenciaPago",
    "Descuento",
    "NumeroSerie",
    "ModeloAuto",
    "MarcaAuto",
    "DescripcionAuto",
    "Cobertura",
    "FechaNacimiento",
    "Email",
    "Telefono",
  ],
  PENDIENTES: [
    "Folio",
    "Poliza",
    "Aseguradora",
    "Vendedor",
    "Cliente",
    "PrimerImporte",
    "ImporteSubsecuente",
    "ImporteTotal",
    "FechaEmisión",
    "InicioVigencia",
    "FinVigencia",
    "FrecuenciaPago",
    "Descuento",
    "NumeroSerie",
    "ModeloAuto",
    "MarcaAuto",
    "DescripcionAuto",
    "Cobertura",
    "FechaNacimiento",
    "Email",
    "Telefono",
  ],
  COTIZACIONES: [
    "Cotizacion",
    "Vigencia",
    "Vendedor",
    "Cliente",
    "FechaNacimiento",
    "Email",
    "Telefono",
    "InicioVigencia",
    "FinVigencia",
    "FrecuenciaPago",
    "ModeloAuto",
    "MarcaAuto",
    "DescripcionAuto",
    "Cobertura"
  ],
}
