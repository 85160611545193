<style scoped>
.dataTables_wrapper {
  overflow-x: auto !important;
}
</style>

<template>
  <div>
    <!-- Filters -->
    <div class="block sm:flex">
      <div class="block w-full md:flex md:space-x-6 space-y-3 sm:space-y-0">
        <div class="w-full">
          <label class="m-auto font-semibold mr-3 w-full">Fecha inicial</label>
          <br />
          <DatePicker :max-date='new Date()' v-model="initialDate.data">
            <template v-slot="{ inputValue, inputEvents }">
              <input placeholder="dd/mm/yyyy" class="form-input rounded mx-auto w-full" :value="inputValue" v-on="inputEvents" id="input-date-start"/>
            </template>
          </DatePicker>
          <small v-show="initialDate.error" class="message-alert text-red-500 block text-right">{{ initialDate.message }}</small>
        </div>
        <div class="w-full">
          <label class="m-auto font-semibold mr-3 w-full">Fecha final</label>
          <br />
          <DatePicker :max-date='new Date()' v-model="finalDate.data">
            <template v-slot="{ inputValue, inputEvents }">
              <input placeholder="dd/mm/yyyy" class="form-input rounded mx-auto w-full" :value="inputValue" v-on="inputEvents" id="input-date-start"/>
            </template>
          </DatePicker>
          <small v-show="finalDate.error" class="message-alert text-red-500 block text-right">{{ finalDate.message }}</small>
        </div>
        <div class="w-full">
          <label class="m-auto font-semibold mr-3 w-full">Tipo de reporte</label>
          <br />
          <select name="select-type-report" id="select-type-report" class="form-select rounded mx-auto w-full" v-on:change="selectReportType($event.target.value)">
            <option value="COTIZACIONES">COTIZACIONES</option>
            <option value="VENTAS">VENTAS</option>
            <!-- <option value="PENDIENTES">PENDIENTES</option> -->
          </select>
          <small v-show="report.error" class="message-alert text-red-500 block text-right">{{ report.message }}</small>
        </div>
      </div>
    </div>

    <div class="w-full my-4">
      <div class="md:float-right pb-4">
        <button class=" w-full md:w-auto lg:w-2/2 xl:w-2/2 sm:mx-auto md:mx-auto lg:mx-2 xl:mx-2 my-1 shadow bg-green-500 hover:bg-green-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded inline-flex items-center" type="button" v-on:click="getReport">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"/>
          </svg>
          Buscar
        </button>

        <button class=" w-full md:w-auto lg:w-2/2 xl:w-2/2 sm:mx-auto md:mx-auto lg:mx-2 xl:mx-2 my-1 shadow bg-orange-400 hover:bg-orange-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded inline-flex items-center" @click="tableRef.exportExcel(initialDate.data,finalDate.data)" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
          </svg>
          Exportar
        </button>
      </div>
    </div>
    
    <div v-if=" reportType == 'COTIZACIONES'">
      <ReportTable ref="tableRef" :arrayFields="dataFields" :rows="rows" :columns="columns" :reportType="reportType"/>
    </div>
    <div v-if=" reportType == 'PENDIENTES'">
      <ReportTable ref="tableRef" :arrayFields="dataFields" :rows="rows" :columns="columns" :reportType="reportType"/>
    </div>
    <div v-if=" reportType == 'VENTAS'">
      <ReportTable ref="tableRef" :arrayFields="dataFields" :rows="rows" :columns="columns" :reportType="reportType"/>
    </div>
  </div>
</template>

<script>
import ReportTable from "@/components/dashboard/reports/ReportTable.vue";
import { onMounted, ref, inject } from "vue";
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import "@/assets/css/custom.css";
import Reports from "@/classes/reports.js";
import Swal from 'sweetalert2';

export default {
  components: {
    ReportTable,
    DatePicker 
  },
  setup() {
    const tableRef = ref();
    const isLoading = inject('isLoading');
    const initialDate = ref({
      data: moment(new Date()).format("DD/MM/YYYY"),
      error: false,
      message: "",
    });
    const finalDate = ref({
      data: moment(new Date()).format("DD/MM/YYYY"),
      error: false,
      message: "",
    });
    const report = ref({
      data: "COTIZACIONES",
      error: false,
      message: "",
    });
    const userFilter = ref({
      data: null,
      error: false,
      message: "",
    });
    let dataFields = ref();
    let columns = ref();
    let rows = ref([]);
    let reportType = ref("");

    const validateDates = (start, end) => {
      initialDate.value.error = false;
      finalDate.value.error = false;
      initialDate.value.message = "";
      finalDate.value.message = "";
      if (typeof start == "undefined" || start == "" || start == null) {
        initialDate.value.error = true;
        initialDate.value.message = "Campo requerido";
        return false;
      } else if (typeof end == "undefined" || end == "" || end == null) {
        finalDate.value.error = true;
        finalDate.value.message = "Campo requerido";
        return false;
      }
      if (start > end) {
        initialDate.value.error = true;
        initialDate.value.message = "La fecha inical supera a la final";
        return false;
      }
      return true;
    };
    const validateParameters = () => {
      if (!validateDates(initialDate.value.data, finalDate.value.data)) {
        return false;
      }
      if (!selectValidate()) {
        return false;
      }
      return true;
    };
    const selectValidate = () => {
      report.value.error = false;
      report.value.message = "";
      userFilter.value.message = "";
      userFilter.value.error = false;
      if (typeof report.value.data == "undefined" || report.value.data == "" || report.value.data == null) {
        report.value.error = true;
        report.value.message = "Campo requerido";
        return false;
      }
      if (typeof userFilter.value.data == "undefined" || userFilter.value.data == "" || userFilter.value.data == null) {
        userFilter.value.error = true;
        userFilter.value.message = "Campo requerido";
        return false;
      }
      return true;
    };
    function selectReportType(event) {
      report.value.data = event;
    }
    const getReport = async () => {
      isLoading.value = true;
      if (validateParameters()) {
        Swal.fire({
          "title": "Validación",
          "icon": "danger",
          "text": "Ocurrio un error en las fechas seleccionadas"
        });
        isLoading.value = false;
        return false;
      }
       
      const reports = new Reports(initialDate.value.data, finalDate.value.data, report.value.data);
      reportType.value = report.value.data;
      dataFields.value = reports.setTableOptions();
      columns.value = reports.setTableHeaders();
      let res = await reports.get();
      
      if (res.status == 200) {
        console.log(res.data.respuesta.message + " - Total de resultados: " + res.data.respuesta.data.resultado.length);
        rows.value = res.data.respuesta.data.resultado;
        let arrayRows = [];
        if(reportType.value == "COTIZACIONES"){
          rows.value.forEach(row =>{
            row.id = "<a href='/cotizaciones?id="+row.id+"'>IR A COTIZACIÓN "+row.id+"</a>";
            arrayRows.push(row);
          });
          rows.value = arrayRows;
        }
        if(reportType.value == "VENTAS"){
          rows.value.forEach(row =>{
            row.id = "<a href='/pago-en-linea?id="+row.id+"'>IR A EMISIÓN "+row.id+"</a>";
            arrayRows.push(row);
          });
          rows.value = arrayRows;
        }
        await tableRef.value.createTable();
        isLoading.value = false;
      }else{
        isLoading.value = false;
        console.log(res.data.respuesta.message);
      }
    };
    onMounted(async () => {
      getReport();
    });
    return {
      tableRef,
      getReport,
      initialDate,
      finalDate,
      rows,
      report,
      userFilter,
      dataFields,
      selectReportType,
      columns,
      reportType,
      isLoading
    };
  },
};
</script>